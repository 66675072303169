<template>
  <div id="data-list-list-view">
    <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$router.push('/MedicalPackage')"
    />
    <!-- Image -->
    <vs-card title="icon" class="mt-5">
      <h4>{{ $t("Image") }}</h4>

      <!-- <div>
        <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</vs-button>
      </div> -->
      <!-- <template v-if="medicalPackageModel.ImagePath">
        <div
          class="img-container w-64 mx-auto flex items-center justify-center"
        >
          <img
            :src="baseURL + medicalPackageModel.ImagePath"
            alt="img"
            class="responsive"
          />
        </div>
      </template> -->
      <vs-button color="primary" type="filled" @click="toggleShow">set Image</vs-button>
      <div class="modify-img justify-between mt-5">
        <!-- <input
          type="file"
          class="hidden"
          ref="updateImgInput"
          @change="updateCurrImg"
          accept="image/*"
        /> -->
        <!-- <vs-button
          class="mr-4"
          type="flat"
          @click="$refs.updateImgInput.click()"
          >{{ $t("UpdateImage") }}</vs-button
        > -->
        <!--   -->

        <!-- <a class="btn" @click="toggleShow">set Image</a> -->
        <my-upload
          field="img"
          @crop-success="cropSuccess"
          @crop-upload-success="cropUploadSuccess"
          @crop-upload-fail="cropUploadFail"
          :noCircle="true"
          v-model="show"
          :value="false"
          :withCredentials="false"
          :width="250"
          :height="300"
          :url="uploadUrl"
          lang-type="en"
          :params="params"
          :headers="headers"
          img-format="png"
        ></my-upload>
        <img class="text-center" :src="baseURL+imgDataUrl" />
      </div>
    </vs-card>

    <!-- IconImage -->
    <vs-card title="icon" class="mt-5">
      <div class="vx-row">
        <div class="vx-col lg:w-1/2">
          <h4>{{ $t("IconImage") }}</h4>

          <template v-if="medicalPackageModel.IconImagePath">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img
                :src="baseURL + medicalPackageModel.IconImagePath"
                alt="img"
                class="responsive"
              />
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateCurrIconImg"
              @change="updateCurrIconImg"
              accept="image/*"
            />
            <vs-button
              class="mr-4"
              type="flat"
              @click="$refs.updateCurrIconImg.click()"
              >{{ $t("UpdateImage") }}</vs-button
            >
            <vs-button
              type="flat"
              color="#999"
              @click="medicalPackageModel.IconImagePath = null"
              >{{ $t("RemoveImage") }}</vs-button
            >
          </div>
        </div>
        <div class="vx-col lg:w-1/2">
          <h4>{{ $t("Color") }}</h4>
          <div class="vx-row">
            <div class=" lg:w-1/3">   <vs-input

            v-model="medicalPackageModel.BgColor"
            class="mt-5 w-full"
            name="color"
            v-validate="'required'"
            type="text"
          /></div>
        <div class=" lg:w-1/2">
          <vs-input
            v-model="medicalPackageModel.BgColor"
            class="mt-5 ml-5 w-full"
            name="color"
            v-validate="'required'"
            type="color"
          />


             <vs-input

            v-model="medicalPackageModel.BgColor"
            class="mt-5 w-full"
            name="color"
            v-validate="'required'"

          />

          </div>
            </div>

          <span class="text-danger text-sm" v-show="errors.has('color')">{{
            errors.first("color")
          }}</span>
        </div>
      </div>
    </vs-card>

    <!-- NameEN -->
    <div class="vx-row">
      <div class="vx-col lg:w-1/2">
        <vs-input
          class="w-full"
          label="NameEN"
          v-validate="'required'"
          v-model="medicalPackageModel.NameEN"
        />
      </div>
      <div class="vx-col lg:w-1/2">
        <vs-input class="w-full" label="NameAR" v-model="medicalPackageModel.NameAR" />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col lg:w-1/2">
        <label class="vs-input--label">Active From </label>
        <datepicker
          v-validate="'required'"
          :format="format"
          placeholder="Active From"
          v-model="medicalPackageModel.StartDate"
          class="vx-col w-full"
          name="from"
        ></datepicker>
      </div>
      <div class="vx-col lg:w-1/2">
        <label class="vs-input--label">Active To </label>
        <datepicker
          :format="format"
          v-validate="'required'"
          placeholder="Active To"
          v-model="medicalPackageModel.EndDate"
          class="vx-col w-full"
          name="from"
        ></datepicker>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col lg:w-1/2 mt-1">
        <label class="vs-input--label">{{ $t("Currency") }} </label>
        <v-select
          label="NameEN"
          class="w-full"
          v-model="medicalPackageModel.CurrencyID"
          :options="currencies"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/2">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Price"
          v-model="medicalPackageModel.Price"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col lg:w-1/3 mt-1">
        <label class="vs-input--label">{{ $t("Medical Package Categorys") }} </label>
        <v-select
          label="NameEN"
          class="w-full"
          v-validate="'required'"
          v-model="medicalPackageModel.MedicalPackageCategoryID"
          :options="MedicalPackageCategories"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/3 mt-1">
        <label class="vs-input--label">{{ $t("Hospitals") }} </label>
        <v-select
          v-validate="'required'"
          label="NameEN"
          class="w-full"
          v-model="medicalPackageModel.HospitalID"
          :options="Hospitals"
          :reduce="(ID) => ID.ID"
          @input="getCountry"
        />
      </div>
      <div class="vx-col lg:w-1/3 mt-1">
        <label class="vs-input--label"> {{ $t("Country") }}</label>
        <v-select
          label="Name"
          name="Country"
          class="mw-full"
          disabled= true
          v-model="medicalPackageModel.CountryID"
          :options="countries"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col lg:w-1/4 mt-10">
        <vs-radio
          v-model="medicalPackageModel.IsPublished"
          :vs-value="true"
          class="mr-4"
          vs-name="IsActive"
          >Published
        </vs-radio>
        <vs-radio
          v-model="medicalPackageModel.IsPublished"
          :vs-value="false"
          class="mr-4"
          vs-name="IsActive"
          >unPublished
        </vs-radio>
      </div>
      <div class="vx-col lg:w-1/4 mt-5">
        <vs-input
          class="w-full"
          label="Duration By Day"
          v-model="medicalPackageModel.DurationByDay"
        />
      </div>
      <div class="vx-col lg:w-1/4 mt-10">
        <vs-radio
          v-model="medicalPackageModel.IsPercentage"
          :vs-value="false"
          class="mr-4"
          vs-name="IsPercentage"
          >Value</vs-radio
        >
        <vs-radio
          v-model="medicalPackageModel.IsPercentage"
          :vs-value="true"
          class="mr-4"
          vs-name="IsPercentage"
          >Percentage</vs-radio
        >
      </div>
      <div class="vx-col lg:w-1/4 mt-5" v-if="medicalPackageModel.IsPercentage == true">
        <vs-input
          class="w-full"
          label="Percentage Discount"
          v-model="medicalPackageModel.PercentageDiscount"
        />
      </div>
      <div class="vx-col lg:w-1/4 mt-5" v-if="medicalPackageModel.IsPercentage == false">
        <vs-input
          class="w-full"
          label="Discount"
          v-model="medicalPackageModel.Discount"
        />
      </div>
    </div>

    <div class="mt-5">
      <p>DescriptionEN</p>
      <div class="w-full">
        <quill-editor class="w-full" v-model="medicalPackageModel.DescriptionEN" />
      </div>
    </div>

    <div class="mt-5">
      <p>DescriptionAR</p>
      <div class="w-full">
        <quill-editor class="w-full" v-model="medicalPackageModel.DescriptionAR" />
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/2">
        <p>Included Items</p>
        <vs-button
          class="mr-4"
          type="filled"
          @click="
            showAddItem = true;
            itemModel = { IsDeleted: false };
          "
          >add</vs-button
        >
        <vs-table :data="medicalPackageIncludedItems">
          <template slot="thead">
            <vs-th>NameEN</vs-th>
            <vs-th>NameAR</vs-th>
            <vs-th>Action</vs-th>
          </template>
          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.NameEN }}</vs-td>
                <vs-td>{{ tr.NameAR }}</vs-td>
                <vs-th>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click="DeleteIncludedItem(tr, indextr)"
                  />
                </vs-th>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      <div class="vx-col lg:w-1/2">
        <p>Excluded Items</p>
        <vs-button
          class="mr-4"
          type="filled"
          @click="
            showExcludeItem = true;
            itemModel = { IsDeleted: false };
          "
          >add</vs-button
        >

        <vs-table :data="medicalPackageExcludedItems">
          <template slot="thead">
            <vs-th>NameEN</vs-th>
            <vs-th>NameAR</vs-th>
            <vs-th>Action</vs-th>
          </template>
          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.NameEN }}</vs-td>
                <vs-td>{{ tr.NameAR }}</vs-td>
                <vs-th>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click="DeleteExcludedItem(tr, indextr)"
                  />
                </vs-th>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
    <div class="vx-row mt-5 justify-end">
      <vs-button class="mr-4" type="flat" @click="$router.go(-1)">back</vs-button>

      <vs-button color="primary" type="filled" @click="submitData">Save</vs-button>
    </div>

    <vs-popup title="" :active.sync="showAddItem">
      <vs-input class="w-full" label="NameEN" v-model="itemModel.NameEN" />
      <vs-input class="w-full" label="NameAR" v-model="itemModel.NameAR" />
      <div class="vx-row mt-5 justify-end">
        <vs-button color="primary" type="filled" @click="SaveAddedItem">Save</vs-button>
      </div>
    </vs-popup>
    <vs-popup title="" :active.sync="showExcludeItem">
      <vs-input class="w-full" label="NameEN" v-model="itemModel.NameEN" />
      <vs-input class="w-full" label="NameAR" v-model="itemModel.NameAR" />
      <div class="vx-row mt-5 justify-end">
        <vs-button color="primary" type="filled" @click="SaveExcludeItem">Save</vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import moduleMedicalPackageCategory from "@/store/settings/MedicalPackageCategory/moduleMedicalPackageCategory.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { domain } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";
import "babel-polyfill";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import EncryptionHandler from "@/http/requests/auth/jwt/EncryptionHandler.js";

// import resizeimg from "resize-img";

export default {
  components: { quillEditor, Datepicker, myUpload },
  data() {
    return {
      show: false,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      imgDataUrl: "",
      IconImagePath: "",
      BgColor: "",
      Package: null,
      baseURL: domain,

      format: "dd-MM-yyyy",
      uploadUrl: domain + "API/Common/UploadFile",
      medicalPackageModel: {
        ImagePath: "",
        IsPercentage: false,
        IsPublished: false,
        MedicalPackageExcludedItems: [],
        MedicalPackageIncludedItems: [],
        IconImagePath: "",
        BgColor: "",
      },
      itemModel: { IsDeleted: false },
      showAddItem: false,
      showExcludeItem: false,
    };
  },
  methods: {
    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("MedicalPackageList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.medicalPackageModel.ImagePath  = res.data;
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    
    updateCurrIconImg(input) {
      debugger;
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.medicalPackageModel.IconImagePath = response.data;
        debugger;
      });
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      this.imgDataUrl = imgDataUrl;
    },
    cropUploadSuccess(jsonData, field) {
      debugger
      this.medicalPackageModel.ImagePath =EncryptionHandler.decrypt(jsonData);
      this.imgDataUrl=EncryptionHandler.decrypt(jsonData);
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    DeleteIncludedItem(item, index) {
      this.medicalPackageModel.MedicalPackageIncludedItems.splice(index, 1);
      if (item.ID) {
        this.$store.dispatch("MedicalPackageList/DeleteIncludedItem", item);
      }
    },
    DeleteExcludedItem(item, index) {
      this.medicalPackageModel.MedicalPackageExcludedItems.splice(index, 1);
      if (item.ID) {
        this.$store.dispatch("MedicalPackageList/DeleteExcludedItem", item);
      }
    },
    SaveAddedItem() {
      this.medicalPackageModel.MedicalPackageIncludedItems.push(this.itemModel);
      this.showAddItem = false;
      this.showExcludeItem = false;
    },
    SaveExcludeItem() {
      this.medicalPackageModel.MedicalPackageExcludedItems.push(this.itemModel);
      this.showAddItem = false;
      this.showExcludeItem = false;
    },
    getCountry() {
      debugger;
      if (this.medicalPackageModel.HospitalID)
        this.medicalPackageModel.CountryID = this.Hospitals.find(
          (x) => x.ID == this.medicalPackageModel.HospitalID
        ).CountryID;
    },
    // updateCurrImg() {
    //   debugger;
    //   this.$store.dispatch("updateCurrPackage", this.Package).then((response) => {
    //     debugger;
    //     this.medicalPackageModel.ImagePath = response.data;
    //   });
    //   debugger;
    // },

    submitData() {
      debugger;
      this.$validator.validateAll().then((result) => {
        if (result) {
          // if (this.imgDataUrl && this.imgDataUrl != "") {
          //   debugger;

            if (this.medicalPackageModel.IsPercentage == true) {
              this.medicalPackageModel.Discount = 0;
            } else {
              this.medicalPackageModel.PercentageDiscount = 0;
            }
            debugger;
            if (this.$route.params.Id > 0) {
              this.$store
                .dispatch(
                  "MedicalPackageList/UpdateMedicalPackage",
                  this.medicalPackageModel
                )
                .then((res) => {
                  window.showUpdateSuccess();
                  this.$router.go(-1);
                })
                .catch((err) => {
                  console.error(err);
                  window.showError(err.response.data.message);
                });
            } else {
              delete this.medicalPackageModel.ID;
              this.$store
                .dispatch(
                  "MedicalPackageList/AddMedicalPackage",
                  this.medicalPackageModel
                )
                .then((res) => {
                  window.showUpdateSuccess();

                  this.$router.go(-1);
                })
                .catch((err) => {
                  console.error(err);
                  window.showError(err.response.data.message);
                });
            }
          // }
        } else {
          this.$vs.notify({
            color: "warning",
            title: "validate All",
            text: this.$t("Please enter needed data"),
          });
        }
      });
    },
  },
  computed: {
    medicalPackageExcludedItems() {
      return this.medicalPackageModel.MedicalPackageExcludedItems;
    },
    medicalPackageIncludedItems() {
      return this.medicalPackageModel.MedicalPackageIncludedItems;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    MedicalPackageCategories() {
      return this.$store.state.MedicalPackageCategoryList.MedicalPackageCategorys;
    },
  },
  created() {
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    if (!moduleMedicalPackageCategory.isRegistered) {
      this.$store.registerModule(
        "MedicalPackageCategoryList",
        moduleMedicalPackageCategory
      );
      moduleMedicalPackageCategory.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$store.dispatch("CurrencyList/GetAllCurrencies");
    this.$store.dispatch("HospitalList/GetAllHospitals", {});
    this.$store.dispatch("MedicalPackageCategoryList/GetAllMedicalPackageCategorys");
    var medicalPackageId = this.$route.params.Id;
    if (medicalPackageId) {
      this.$store
        .dispatch("MedicalPackageList/GetMedicalPackage", medicalPackageId)
        .then((res) => {
          this.medicalPackageModel = res.data.Data;
          this.imgDataUrl =res.data.Data.ImagePath;
          if (this.medicalPackageModel.PercentageDiscount > 0) {
            this.medicalPackageModel.IsPercentage = true;
          } else {
            this.medicalPackageModel.IsPercentage = false;
          }
        });
      this.$store.dispatch("CountryList/GetAllCountries");
      this.getCountry();
    }
  },
};
</script>
<style scoped>
.v-select {
  background: white;
}
.quill-editor {
  background: white;
}
.ql-snow {
  background: white;
}
</style>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
