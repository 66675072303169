/*=========================================================================================
  File Name: moduleMedicalPackageActions.js
  Description: MedicalPackage Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddMedicalPackage({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/AddMedicalPackage", item)
                .then((response) => {
                    commit('ADD_ITEM', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    SearchMedicalPackage({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/SearchMedicalPackage", search)
                .then((response) => {
                    commit('SET_MedicalPackage', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    UpdateMedicalPackage(context, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/UpdateMedicalPackage", item)
                .then((response) => {
                    // commit('UPDATE_MedicalPackages', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SetPublishMedicalPackage({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackage/SetPublishMedicalPackage", item)
                .then((response) => {
                    commit('UPDATE_MedicalPackages', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetMedicalPackage({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.get("api/MedicalPackage/GetMedicalPackage?packageId=" + itemid)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteMedicalPackage({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/MedicalPackage/DeleteMedicalPackage?packageId=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteIncludedItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/MedicalPackage/DeleteIncludedItem?includedItemId=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteExcludedItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/MedicalPackage/DeleteExcludedItem?excludedItemId=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UploadImage(context, image) {
        const formData = new FormData();
        formData.append("file",image, image.name);
        return new Promise((resolve, reject) => {
          axios
            .post("/API/Common/UploadFile", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => {
                debugger;
              if (response.status == 200) {
                resolve(response);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },

}
